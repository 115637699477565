"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Device;
(function (Device) {
    Device["Browser"] = "browser";
    Device["Server"] = "server";
    Device["Native"] = "native";
})(Device = exports.Device || (exports.Device = {}));
var Browser = Device.Browser, Server = Device.Server, Native = Device.Native;
var canUseDOM = !!(typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement);
var canUseNative = typeof navigator != 'undefined' && navigator.product == 'ReactNative';
var device = canUseNative ? Native : canUseDOM ? Browser : Server;
var SSRObject = {
    isBrowser: device === Browser,
    isServer: device === Server,
    isNative: device === Native,
    device: device,
    canUseWorkers: typeof Worker !== 'undefined',
    canUseEventListeners: device === Browser && !!window.addEventListener,
    canUseViewport: device === Browser && !!window.screen,
};
// TODO: instead of this, do a polyfill for `Object.assign` https://www.npmjs.com/package/es6-object-assign
var assign = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return args.reduce(function (acc, obj) { return (__assign(__assign({}, acc), obj)); }, {});
};
var values = function (obj) { return Object.keys(obj).map(function (key) { return obj[key]; }); };
var toArrayObject = function () { return assign((values(SSRObject), SSRObject)); };
var useSSRObject = toArrayObject();
exports.weAreServer = function () {
    SSRObject.isServer = true;
    useSSRObject = toArrayObject();
};
exports.useSSR = function () { return useSSRObject; };
exports.default = exports.useSSR;
